// src/App.js
import React, { Suspense, lazy } from 'react';
import { Routes, Route } from 'react-router-dom';
import Layout from './Components/Layout';
import { UserProvider } from './UserContext'; // Importa UserProvider
import { withAuth } from './hocs/withAuth';

const Login = lazy(() => import('./Pages/Login/Login'));
const Home = lazy(() => import('./Pages/Home/Home'));
const UsersManagement = lazy(() => import('./Pages/UsersManagement'));
const Apps = lazy(() => import('./Pages/AppsManagement'));
const Roles = lazy(() => import('./Pages/RolesManagement'));
const UsersRoles = lazy(() => import('./Pages/UsersRolesManagement'));
const Functionalities = lazy(() => import('./Pages/FunctionalitiesManagement'));

function App() {
  const LayoutWithAuth = withAuth(Layout);
  const HomeWithAuth = withAuth(Home);
  const UsersManagementWithAuth = withAuth(UsersManagement);
  const AppsWithAuth = withAuth(Apps);
  const RolesWithAuth = withAuth(Roles);
  const UsersRolesWithAuth = withAuth(UsersRoles);
  const FunctionalitiesWithAuth = withAuth(Functionalities);

  // Estilo en línea para aplicar la fuente a toda la app
  const appStyle = {
    fontFamily: 'Nunito, sans-serif',
    // Otros estilos globales que quieras aplicar
  };

  return (
    <UserProvider>
      <div className="App" style={appStyle}>
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            <Route path='/login' element={<Login />} />
            <Route element={<LayoutWithAuth />}>
              <Route path="/" element={<HomeWithAuth />} />
              <Route path="/users/new" element={<UsersManagementWithAuth />} />
              <Route path="/users/:userId" element={<UsersManagementWithAuth />} />
              <Route path="/users" element={<UsersManagementWithAuth />} />
              <Route path="/settings/apps" element={<AppsWithAuth />} />
              <Route path="/settings/roles" element={<RolesWithAuth />} />
              <Route path="/users_roles" element={<UsersRolesWithAuth />} />
              <Route path="/settings/functionalities" element={<FunctionalitiesWithAuth />} />
            </Route>
          </Routes>
        </Suspense>
      </div>
    </UserProvider>
  );
}

export default App;