import React, { useState, useEffect } from 'react';
import {
  TextField,
  Button,
  Box,
  Switch,
  Autocomplete,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Snackbar,
  Alert,
} from '@mui/material';
import { createUser, updateUser, getCodesFor, getCountries, getUser } from '../../Services/api';
import XRegExp from 'xregexp';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import { useUser } from '../../UserContext'; // Importar el contexto del usuario

const UsersForm = ({ user, onSave, onCancel }) => {
  const { user: currentUser } = useUser(); // Obtener el usuario del contexto

  //console.log(useUser(),"343");
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    type: '',
    nick: '',
    first_name: '',
    last_name: '',
    nationality: '',
    gender: 'M',
    activity_id: '',
    marital_id: '',
    phone: '',
    is_pep: false,
  });

  const [activities, setActivities] = useState([]);
  const [maritalStatuses, setMaritalStatuses] = useState([]);
  const [countries, setCountries] = useState([]);
  const [errors, setErrors] = useState({});
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  const isViewer = currentUser && currentUser.permissions && currentUser.permissions.some(permission => permission.tag === 'VIEWER');

  // console.log('Is current user a VIEWER?', isViewer);
  // Fetch user data when the user prop changes
  useEffect(() => {
    const fetchUserData = async () => {
      if (user) {
        try {
          const response = await getUser(user.id);
          setFormData({
            email: response.data.email || '',
            password: '',
            type: response.data.type || '',
            nick: response.data.nick || '',
            first_name: response.data.first_name || '',
            last_name: response.data.last_name || '',
            nationality: response.data.nationality || '',
            gender: response.data.gender || 'M',
            activity_id: response.data.activity_id || '',
            marital_id: response.data.marital_id || '',
            phone: response.data.phone || '',
            is_pep: Boolean(response.data.is_pep),
          });
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      } else {
        // Reset form data if user is null
        setFormData({
          email: '',
          password: '',
          type: '',
          nick: '',
          first_name: '',
          last_name: '',
          nationality: '',
          gender: 'M',
          activity_id: '',
          marital_id: '',
          phone: '',
          is_pep: false,
        });
      }
    };

    fetchUserData();
  }, [user]);

  // Fetch codes for activities, marital statuses, and countries
  useEffect(() => {
    const fetchCodes = async () => {
      try {
        const activitiesData = await getCodesFor('ACT');
        const maritalStatusesData = await getCodesFor('MAR');
        const countriesData = await getCountries();
        setActivities(activitiesData);
        setMaritalStatuses(maritalStatusesData);
        setCountries(countriesData.data);
      } catch (error) {
        console.error('Error fetching codes:', error);
      }
    };
    fetchCodes();
  }, []);

  const handleChange = (e) => {
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    setFormData({ ...formData, [e.target.name]: value });
  };

  const validate = () => {
    const newErrors = {};

    // Validaciones replicando las reglas de Laravel
    if (!formData.email) {
      newErrors.email = 'Email is required.';
    } else if (!/^[\w.%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(formData.email)) {
      newErrors.email = 'Invalid email format.';
    }

    if (formData.password && (formData.password.length < 6 || formData.password.length > 255)) {
      newErrors.password = 'Password must be between 6 and 255 characters.';
    }

    if (!formData.type || !['B', 'C'].includes(formData.type)) {
      newErrors.type = 'Type must be B or C.';
    }

    if (formData.first_name && !XRegExp('^[\\p{L}\\s.]+$').test(formData.first_name)) {
      newErrors.first_name = 'First name can only contain letters and spaces.';
    }

    if (formData.last_name && !XRegExp('^[\\p{L}\\s.]+$').test(formData.last_name)) {
      newErrors.last_name = 'Last name can only contain letters and spaces.';
    }
    
    if (formData.nationality && !XRegExp('^[\\p{L}\\s.]+$').test(formData.nationality)) {
      newErrors.nationality = 'Nationality can only contain letters and spaces.';
    }

    if (formData.gender && !['M', 'F'].includes(formData.gender)) {
      newErrors.gender = 'Gender must be M or F.';
    }

    if (formData.phone && (!/^\d+$/.test(formData.phone) || formData.phone.length > 191)) {
      newErrors.phone = 'Phone must be a number and up to 191 digits.';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      try {
        const submitData = { ...formData };

        if (!submitData.password) {
          delete submitData.password;
        }

        if (user) {
          await updateUser(user.id, submitData);
        } else {
          await createUser(submitData);
        }

        setSnackbarMessage('User saved successfully');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
        onSave();
      } catch (error) {
        console.error('Error saving user:', error);
        setSnackbarMessage('Error saving user');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2, backgroundColor: 'white', p: 3, borderRadius: 2, boxShadow: 1 }}>
      <TextField
        name="email"
        label="Email"
        value={formData.email}
        onChange={handleChange}
        required
        fullWidth
        error={!!errors.email}
        helperText={errors.email}
        sx={{ mb: 2 }}
        InputProps={{
          readOnly: isViewer, // Solo lectura si el usuario es VIEWER
        }}
      />
      <TextField
        name="password"
        label="Password"
        type="password"
        value={formData.password}
        onChange={handleChange}
        placeholder="Enter a new password if you want to change it"
        fullWidth
        sx={{ mb: 2 }}
        error={!!errors.password}
        helperText={errors.password}
        required={!user}
        InputProps={{
          readOnly: isViewer, // Solo lectura si el usuario es VIEWER
        }}
      />






{isViewer ? (
  <TextField
    label="Type"
    value={formData.type === 'B' ? 'Business' : formData.type === 'C' ? 'Consumer' : ''}
    fullWidth
    sx={{ mb: 2 }}
    InputProps={{
      readOnly: true,
    }}
  />
) : (
<FormControl component="fieldset" sx={{ mb: 2 }}>
  <RadioGroup
    name="type"
    value={formData.type}
    onChange={handleChange} // Usando la misma función de manejo del cambio
    row
  >
    <FormControlLabel
      value="B"
      control={<Radio disabled={isViewer} />} // Deshabilitado si es solo vista
      label="Business"
    />
    <FormControlLabel
      value="C"
      control={<Radio disabled={isViewer} />} // Deshabilitado si es solo vista
      label="Consumer"
    />
  </RadioGroup>
  {errors.type && <p style={{ color: 'red' }}>{errors.type}</p>}
</FormControl>
)}






      <TextField
        name="nick"
        label="Nick"
        value={formData.nick}
        onChange={handleChange}
        fullWidth
        sx={{ mb: 2 }}
        error={!!errors.nick}
        helperText={errors.nick}
        InputProps={{
          readOnly: isViewer, // Solo lectura si el usuario es VIEWER
        }}
      />
      <TextField
        name="first_name"
        label="First Name"
        value={formData.first_name}
        onChange={handleChange}
        fullWidth
        sx={{ mb: 2 }}
        error={!!errors.first_name}
        helperText={errors.first_name}
        InputProps={{
          readOnly: isViewer, // Solo lectura si el usuario es VIEWER
        }}
      />
      <TextField
        name="last_name"
        label="Last Name"
        value={formData.last_name}
        onChange={handleChange}
        fullWidth
        sx={{ mb: 2 }}
        error={!!errors.last_name}
        helperText={errors.last_name}
        InputProps={{
          readOnly: isViewer, // Solo lectura si el usuario es VIEWER
        }}
      />
  
  
          {isViewer ? (
          <TextField
            label="Nationality"
            value={countries.find((country) => country.id === formData.nationality)?.name || ''}
            fullWidth
            sx={{ mb: 2 }}
            InputProps={{
              readOnly: true,
            }}
          />
        ) : (
        <Autocomplete
          options={countries}
          getOptionLabel={(option) => option.name}
          onChange={(event, newValue) => setFormData({ ...formData, nationality: newValue ? newValue.id : '' })}
          value={countries.find((country) => country.id === formData.nationality) || null}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Nationality"
              fullWidth
              error={!!errors.nationality}
              helperText={errors.nationality}
              sx={{ mb: 2 }}
            />
          )}
        />
        )}


      <FormControl component="fieldset" sx={{ mb: 2 }}>
        <RadioGroup name="gender" value={formData.gender} onChange={handleChange} row>
          <FormControlLabel value="M" control={<Radio disabled={isViewer} />} label="Male" />
          <FormControlLabel value="F" control={<Radio disabled={isViewer} />} label="Female" />
        </RadioGroup>
        {errors.gender && <p style={{ color: 'red' }}>{errors.gender}</p>}
      </FormControl>


          {isViewer ? (
      <TextField
        label="Activity"
        value={activities.find((activity) => activity.id === formData.activity_id)?.description || ''}
        fullWidth
        sx={{ mb: 2 }}
        InputProps={{
          readOnly: true,
        }}
      />
    ) : (
      <Autocomplete
        options={activities}
        getOptionLabel={(option) => option.description}
        onChange={(event, newValue) => setFormData({ ...formData, activity_id: newValue ? newValue.id : '' })}
        value={activities.find((activity) => activity.id === formData.activity_id) || null}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Activity"
            fullWidth
            sx={{ mb: 2 }}
          />
        )}
      />
    )}


    {isViewer ? (
      <TextField
        label="Marital Status"
        value={maritalStatuses.find((status) => status.id === formData.marital_id)?.description || ''}
        fullWidth
        sx={{ mb: 2 }}
        InputProps={{
          readOnly: true,
        }}
      />
    ) : (
      <Autocomplete
        options={maritalStatuses}
        getOptionLabel={(option) => option.description}
        onChange={(event, newValue) => setFormData({ ...formData, marital_id: newValue ? newValue.id : '' })}
        value={maritalStatuses.find((status) => status.id === formData.marital_id) || null}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Marital Status"
            fullWidth
            sx={{ mb: 2 }}
          />
        )}
      />
    )}











      <TextField
        name="phone"
        label="Phone"
        value={formData.phone}
        onChange={handleChange}
        fullWidth
        sx={{ mb: 2 }}
        error={!!errors.phone}
        helperText={errors.phone}
        InputProps={{
          readOnly: isViewer, // Solo lectura si el usuario es VIEWER
        }}
      />
      <FormControlLabel
        control={<Switch checked={formData.is_pep} onChange={handleChange} name="is_pep" disabled={isViewer} />}
        label="Is PEP"
      />
      <Box sx={{ display: 'flex', gap: 2, marginTop: '30px' }}>
        {!isViewer && ( // Ocultar botón de actualización si el usuario es VIEWER
          <Button type="submit" variant="contained" color="primary">
            {user ? 'Update' : 'Create'}
          </Button>
        )}
        <Button variant="contained" color="secondary" onClick={onCancel}>
          Cancel
        </Button>
      </Box>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity={snackbarSeverity}
          iconMapping={{
            success: <CheckCircleIcon sx={{ color: 'white' }} />,
            error: <ErrorIcon sx={{ color: 'white' }} />,
          }}
          sx={{
            top: { xs: '110px', sm: '110px' },
            width: '100%',
            backgroundColor: snackbarSeverity === 'success' ? '#3FAE10' : '#f44336',
            color: 'white',
          }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default UsersForm;
