import React, { useRef, useEffect, useCallback, useState } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { PiWalletLight, PiAppWindowDuotone, PiBarcodeLight, PiArchiveDuotone } from "react-icons/pi";
import axios from 'axios';
import { Dialog, DialogTitle, DialogContent } from '@mui/material';
import "./sidebar.scss";
import UsersForm from '../Users/UsersForm';

const Sidebar = ({ user }) => {
  const refSidebar = useRef(null);
  const refLockIcon = useRef(null);
  const refClose = useRef(null);
  const refSubMenu3Items = useRef(null);
  const refSubMenu3Icon = useRef(null);
  const location = useLocation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname.includes('/settings')) {
      toggleSubMenu00(true);
    }
  }, [location.pathname]);

  const toggleSubMenu00 = useCallback((force = false) => {
    toggleSubMenu(refSubMenu3Icon, refSubMenu3Items, force);
  }, []);

  const toggleSubMenu = useCallback((iconRef, itemsRef, force) => {
    if (iconRef.current && itemsRef.current) {
      const currentClassName = iconRef.current.className;
      const currentClassNameItems = itemsRef.current.className;
      if (currentClassNameItems.includes('hidden') || force) {
        iconRef.current.className = currentClassName.replace('bx-chevron-right', 'bx-chevron-down');
        itemsRef.current.classList.remove('hidden');
      } else {
        iconRef.current.className = currentClassName.replace('bx-chevron-down', 'bx-chevron-right');
        itemsRef.current.classList.add('hidden');
      }
    }
  }, []);

  const toggleLock = useCallback(() => {
    if (refSidebar.current && refLockIcon.current) {
      refSidebar.current.classList.toggle("locked");

      if (!refSidebar.current.classList.contains('locked')) {
        refSidebar.current.classList.add('hoverable');
        refLockIcon.current.classList.replace('bx-lock-alt', 'bx-lock-open-alt');
      } else {
        refSidebar.current.classList.remove('hoverable');
        refLockIcon.current.classList.replace('bx-lock-open-alt', 'bx-lock-alt');
      }
    }
  }, []);

  const hideSidebar = useCallback(() => {
    if (refSidebar.current && refSidebar.current.classList.contains('hoverable')) {
      refSidebar.current.classList.add('close');
    }
  }, []);

  const showSidebar = useCallback(() => {
    if (refSidebar.current && refSidebar.current.classList.contains('hoverable')) {
      refSidebar.current.classList.remove('close');
    }
  }, []);

  const logout = useCallback(() => {
    axios.post(process.env.REACT_APP_URL_API + 'logout', {}, {
      withCredentials: true
    }).then(function (response) {
      // Handle successful logout
    }).catch(function (error) {
      console.error('Logout error:', error);
    });
  }, []);

  const handleProfileClick = async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_URL_API + 'profile', { 
        withCredentials: true 
      });

      setSelectedUser(response.data); // Set the selected user based on the response
      setIsModalOpen(true);
    } catch (error) {
      console.error('Error fetching user profile:', error);
      alert('Failed to fetch profile. Please try again.');
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedUser(null);
  };

  // Función actualizada para manejar permisos
  const hasPermission = useCallback((tag) => {
    if (user && user.permissions) {
      // Verificar si el usuario tiene el rol VIEWER
      const isViewer = user.permissions.some(permission => permission.tag === 'VIEWER');

      // Si el usuario tiene VIEWER, limitar acceso solo a lo que VIEWER permite
      if (isViewer) {
        return user.permissions.some(permission => permission.tag === tag || permission.tag === 'VIEWER');
      }

      // Verificar si el usuario tiene el rol SUPERADMIN
      const isSuperAdmin = user.permissions.some(permission => permission.tag === 'SUPERADMIN');

      // Si el usuario tiene SUPERADMIN, permitir acceso a todo
      if (isSuperAdmin) {
        return true;
      }

      // Comprobar permiso específico
      return user.permissions.some(permission => permission.tag === tag);
    }
    return false; // No se encontraron permisos
  }, [user]);

  if (!user) {
    return null;
  }

  return (
    <>
      <nav ref={refSidebar} className="sidebar locked" onMouseLeave={hideSidebar} onMouseEnter={showSidebar}>
        <div className="logo_items flex">
          <span className="nav_image"></span>
          <span className="logo_name">Core</span>

          <i ref={refLockIcon} className='bx bx-lock-alt' id="lock-icon" title="Unlock sidebar" onClick={toggleLock}></i>
          <i ref={refClose} className='bx bx-x' id="sidebar-close"></i>
        </div>

        <div className="menu_container">
          <div className="menu_items">
            <ul className="menu_item">
              {/* Profile button at the top of the dashboard */}

              {/*}    

              {hasPermission('PROFILE_USER') && (
                <li className="item">
                  <p className="link flex" onClick={handleProfileClick}>
                    <i className='bx bx-user'></i>
                    <span>Profile</span>
                  </p>
                </li>
              )}

                */}

        <div className="user_info">
          {/* Mostrar el email del usuario con opacidad */}
          <p style={{ color: '#fff', opacity: 0.7, padding: '10px', textAlign: 'left', fontStyle: 'italic' }}>
            {user.email}
          </p>
        </div>

              <div className="menu_title flex">
                <span className="title">Dashboard</span>
                <span className="line"></span>
              </div>

              {hasPermission('CORE_USERS') && (
                <li className="item">
                  <Link to="/users" style={{ textDecoration: "none" }}>
                    <p className="link flex" onClick={() => console.log('Link to Users clicked!')}>
                      <i className='bx bx-user'></i>
                      <span>Users</span>
                    </p>
                  </Link>
                </li>
              )}

              {hasPermission('SETTINGS') && (
                <li className="item">
                  <p className="link flex" onClick={toggleSubMenu00}>
                    <i><PiWalletLight /></i>
                    <span>Settings</span>
                    <i ref={refSubMenu3Icon} className='ml-auto bx bx-chevron-right'></i>
                  </p>
                  <ul className="sub_menu_item hidden" ref={refSubMenu3Items}>
                    {hasPermission('APPS') && (
                      <Link to="/settings/apps" style={{ textDecoration: "none" }}>
                        <li className="item">
                          <p className="link flex">
                            <i className='bx bx-payment'><PiAppWindowDuotone /></i>
                            <span>Apps</span>
                          </p>
                        </li>
                      </Link>
                    )}

                    {hasPermission('ROLES') && (
                      <Link to="/settings/roles" style={{ textDecoration: "none" }}>
                        <li className="item">
                          <p className="link flex">
                            <i className='bx bx-payment'><PiBarcodeLight /></i>
                            <span>Roles</span>
                          </p>
                        </li>
                      </Link>
                    )}

                    {hasPermission('USER_ROLES') && (
                      <Link to="/users_roles" style={{ textDecoration: "none" }}>
                        <li className="item">
                          <p className="link flex">
                            <i className='bx bx-user'></i>
                            <span>Users Roles</span>
                          </p>
                        </li>
                      </Link>
                    )}

                    {hasPermission('FUNCTIONALITIES') && (
                      <Link to="/settings/functionalities" style={{ textDecoration: "none" }}>
                        <li className="item">
                          <p className="link flex">
                            <i className='bx bx-service'><PiArchiveDuotone /></i>
                            <span>Functionalities</span>
                          </p>
                        </li>
                      </Link>
                    )}
                  </ul>
                </li>
              )}
            </ul>
          </div>
          <div className="bottom-content">
            <Link to="/login" style={{ textDecoration: "none" }} onClick={logout}>
              <li className="item">
                <p className="link flex">
                  <i className='bx bx-log-out icon'></i>
                  <span>Logout</span>
                </p>
              </li>
            </Link>
          </div>
        </div>
      </nav>

      {/* Modal using Material-UI */}
      <Dialog open={isModalOpen} onClose={closeModal}>
        <DialogTitle>Profile</DialogTitle>
        <DialogContent>
          <UsersForm 
            user={selectedUser} 
            onSave={closeModal}   
            onCancel={closeModal}
            modal={true}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}

export default Sidebar;
